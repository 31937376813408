// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-contact-menu-styles__contactMenu {\n  height: 118px;\n  z-index: 98;\n  margin-left: 80px;\n}\n\n.src-contact-menu-styles__contactMenu input.src-contact-menu-styles__formControlResize {\n  max-width: 100%;\n}\n\n.src-contact-menu-styles__individualName {\n  overflow-x: auto;\n  white-space: nowrap;\n  padding-bottom: 1.5rem;\n}\n\n.src-contact-menu-styles__contactType {\n  clear: both;\n  margin-left: 7rem;\n  margin-top: -1.8rem;\n  display: flex;\n  align-items: center;\n}\n\n.src-contact-menu-styles__contactType .src-contact-menu-styles__cps-icon {\n  font-size: 1.6rem !important;\n}\n\n.src-contact-menu-styles__name {\n  display: inline-block;\n  width: calc(100% - 7.3rem);\n}\n\n.src-contact-menu-styles__startVideoChat {\n  display: inline-block;\n  margin-top: -0.6rem;\n  margin-left: 1.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"contactMenu": "src-contact-menu-styles__contactMenu",
	"formControlResize": "src-contact-menu-styles__formControlResize",
	"individualName": "src-contact-menu-styles__individualName",
	"contactType": "src-contact-menu-styles__contactType",
	"cps-icon": "src-contact-menu-styles__cps-icon",
	"name": "src-contact-menu-styles__name",
	"startVideoChat": "src-contact-menu-styles__startVideoChat"
};
module.exports = exports;
