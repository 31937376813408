// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-participants-styles__dropDownSquare {\n  cursor: pointer;\n}\n.src-participants-styles__dropDownSquare:hover {\n  opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"dropDownSquare": "src-participants-styles__dropDownSquare"
};
module.exports = exports;
