import React, { lazy, Suspense } from "react";
import { catchSyncStacktrace, catchError } from "auto-trace";
import Cancelable from "react-disposable-decorator";
import { CpNameChip, CpButton, CpTooltip, CpLoader } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { TopnavSecondary } from "primary-navbar!sofe";
import toasts from "toast-service!sofe";
import { userHasOnlyLicense } from "cp-client-auth!sofe";

import { archiveContact, unarchiveContact, deleteContact } from "./contacts.resource.js";
import styles from "./contact-menu.styles.css";
import Participants from "./participants.component.js";
import { ContactNameDropdown } from "./dropdown.component.js";
import Tabs from "./tabs.component.js";
import { archiveContactFromLocalStorage } from "./recent-contacts.helper.js";

const CreateEditContactModal = lazy(async () => {
  const contactsUI = await SystemJS.import("contacts-ui!sofe");
  return await contactsUI.getCreateEditContactModal();
});

class ContactMenu extends React.Component {
  state = {
    contactId: this.props.contact.id,
    form: {
      business_name: this.props.contact.business_name,
      first_name: this.props.contact.first_name,
      last_name: this.props.contact.last_name,
      middle_name: this.props.contact.middle_name,
    },
    contactType: this.props.contact.contact_type,
    isEditing: false,
    showContactEditModal: false,
    showDeleteContactsModal: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.contact.id !== prevState.contactId || nextProps.contact.contact_type !== prevState.contactType) {
      return {
        form: {
          business_name: nextProps.contact.business_name,
          first_name: nextProps.contact.first_name,
          last_name: nextProps.contact.last_name,
        },
        contactType: nextProps.contact.contact_type,
        isEditing: false,
        showDeleteContactsModal: false,
      };
    } else return null;
  }

  handleFormChange = (property, event) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: event.target.value,
      },
    });
  };

  showEditModal = () => {
    this.setState({
      showContactEditModal: true,
    });
  };

  hideEditModal = (updated) => {
    if (updated) {
      window.dispatchEvent(new CustomEvent("contacts-ui::contact-updated"));
    }
    this.setState({
      showContactEditModal: false,
    });
  };

  showDeleteModal = () => {
    this.setState({
      showDeleteContactsModal: true,
    });
  };

  hideDeleteModal = () => {
    this.setState({
      showDeleteContactsModal: false,
    });
  };

  setInputRef = (ref) => {
    this.input = ref;
  };

  startEditing = (event) => {
    this.setState(
      {
        isEditing: true,
      },
      () => {
        this.input.focus();
        this.input.select();
      }
    );
  };

  stopEditing = (event) => {
    this.setState({
      isEditing: false,
    });
  };

  cancel = (event) => {
    this.resetState(this.props);
    this.stopEditing();
  };

  archiveContact = (event) => {
    archiveContact(this.props.contact.id)
      .then(() => {
        toasts.successToast(`Successfully archived ${this.props.contact.name}`);

        archiveContactFromLocalStorage(this.props.contact.id, window.loggedInUser.id);
        window.location = "/#/clients";
      })
      .catch((ex) => {
        toasts.warningToast("Sorry, we couldn't archive the contact. Please try again later.");
      });
  };

  unarchiveContact = (event) => {
    unarchiveContact(this.props.contact.id).subscribe(
      () => {
        toasts.successToast(`Successfully unarchived ${this.props.contact.name}`);
        window.dispatchEvent(new CustomEvent("contacts-ui::contact-updated"));
        this.props.refetchContact();
      },
      catchError((err, throwErr) => {
        err.showToast = false;
        toasts.warningToast("Sorry, we couldn't unarchive the contact. Please try again later.");
        throwErr(err);
      })
    );
  };

  deleteContact = (event) => {
    deleteContact(this.props.contact.id)
      .then(() => {
        archiveContactFromLocalStorage(this.props.contact.id, window.loggedInUser.id);
        window.location = "/#/clients";
      })
      .catch((ex) => {
        toasts.warningToast("Sorry, we couldn't delete the contact. Please try again later.");
      });
  };

  hasAccess = (permission) => {
    return this.props.loggedInUser.permissions && this.props.loggedInUser.permissions[permission];
  };

  render() {
    const { contact, loggedInUser } = this.props;
    const { is_business, business_name, first_name, middle_name, last_name, display_name, id, users } = contact;
    const name = display_name
      ? display_name
      : is_business // business_name could be set for non-businesses. We want to show first/middle/last for these.
      ? business_name
      : [first_name, middle_name, last_name].filter((i) => i).join(" ");
    const transcriptsOnly = userHasOnlyLicense(loggedInUser, "transcripts");

    const staticName = (
      <div
        className={`${styles.name} ${
          loggedInUser.role === "TeamMember" ? (transcriptsOnly ? "cp-mt-24" : "cp-mt-12") : "cp-mt-16"
        }`}
      >
        <CpTooltip text={name} disabled={name.length < 25} delay={200} position={"bottom"}>
          <div style={{ maxWidth: "calc(100% - 7rem)" }} className="cps-title cps-pull-left cps-ellipsis ">
            {name}
          </div>
        </CpTooltip>
        {this.hasAccess("contacts_archive") || this.hasAccess("contacts_create_edit") ? (
          <ContactNameDropdown
            showContactEditModal={this.showEditModal}
            archiveContact={this.archiveContact}
            unarchiveContact={this.unarchiveContact}
            showDeleteModal={this.showDeleteModal}
            contact={contact}
          />
        ) : null}
      </div>
    );

    const contactTypeValue = this.state.contactType;
    const capitalizedContactType = contactTypeValue.charAt(0).toUpperCase() + contactTypeValue.slice(1);

    const contactType = (
      <div
        className={`cps-pull-left ${styles.contactType}${contactTypeValue == "client" ? " cps-color-primary" : ""}${
          contactTypeValue == "prospect" ? " cps-info" : ""
        }${contactTypeValue == "other" ? " cps-gray-10" : ""}`}
      >
        <span
          className={`cps-icon${!is_business ? " cps-icon-client" : ""}${is_business ? " cps-icon-business" : ""}`}
          style={{ color: "inherit" }}
        />
        <span className="cps-caption cps-margin-left-4">{capitalizedContactType}</span>
      </div>
    );

    return (
      <TopnavSecondary className={styles.contactMenu}>
        <div className="cps-topnav-secondary__content +tall">
          <div className="cps-row">
            <div className="cps-col-xs-8">
              <div>
                <div className="cps-pull-left cps-margin-top-16 cps-margin-right-24">
                  <CpNameChip size="large" name={name} />
                </div>

                <span className="cps-color-primary-text cps-wt-semibold">{staticName}</span>
                {!transcriptsOnly && loggedInUser.role === "TeamMember" && contactType}
              </div>
            </div>
            <div className="cps-col-xs-4">
              {!transcriptsOnly && this.hasAccess("team_member") && (
                <Participants hasAccess={this.hasAccess} client={contact} loggedInUser={loggedInUser} />
              )}
            </div>
          </div>
        </div>
        <Tabs contact={contact} hasAccess={this.hasAccess} userRole={loggedInUser.role} />
        {this.state.showContactEditModal && (
          <Suspense fallback={<CpLoader />}>
            <CreateEditContactModal onModalHide={this.hideEditModal} contact={contact} isCreate={false} />
          </Suspense>
        )}
        {this.state.showDeleteContactsModal ? (
          <div className="cps-modal">
            <div className="cps-modal__screen" />
            <div className="cps-modal__dialog cps-card__height-3">
              <div className="cps-card__header cps-subheader-sm">
                <span>Delete Contact</span>
                <a className="cps-modal__dialog__close cps-icon cps-icon-close" onClick={this.hideDeleteModal} />
              </div>
              <div className="cps-card__banner +large +bg-warning">
                <i className="cps-icon cps-icon-error cps-warning">
                  <span>Welcome to the Danger Zone! Please read this.</span>
                </i>
              </div>
              <div className="cps-card__body cp-clients-index__modal-body">
                {`By deleting this contact, you will be permanently deleting all billing items, contact data, dates, ${
                  featureEnabled("toggle_engagements_rename") ? "resolution cases" : "engagements"
                }, files, notes, and tasks related to the contact. You will not be able to restore this data. Are you sure you want to delete this contact?`}
              </div>
              <hr className="cps-margin-0" />
              <div className="cps-card__body">
                <CpButton btnType="primary" className="cp-mr-12" onClick={this.deleteContact}>
                  Delete contact
                </CpButton>
                <CpButton btnType="tertiary" onClick={this.hideDeleteModal}>
                  Cancel
                </CpButton>
              </div>
            </div>
          </div>
        ) : null}
      </TopnavSecondary>
    );
  }
}

export default Cancelable(ContactMenu);
