import React from "react";
import { CpModal, CpButton, CpEmptyState, constants } from "canopy-styleguide!sofe";

export default function ClientUsageModal({ show, onClose, clientsRemaining, clientLimit }) {
  return (
    <CpModal show={show} onClose={onClose} className="cp-p-20">
      <div className="cp-p-8" style={{ position: "absolute", right: 0, top: 0 }}>
        <CpButton btnType="icon" aria-label="Close" icon="close-small" onClick={onClose} />
      </div>
      <CpEmptyState
        img="es_contacts"
        text="Time to upgrade"
        subText={`You only have ${clientsRemaining} of ${clientLimit} clients available. Call ${constants.canopyPhone} to upgrade.`}
        cta={
          <CpButton btnType="flat" anchor href="#/global-settings/account-management">
            View my subscription
          </CpButton>
        }
      />
    </CpModal>
  );
}
