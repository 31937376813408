import React from "react";
import PropTypes from "prop-types";
import canopyUrls from "canopy-urls!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { UserTenantProps, userHasOnlyLicense } from "cp-client-auth!sofe";
import { notifyAnalytics } from "./analytics.resource";

@UserTenantProps()
export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.getActiveTab(this.props.userRole),
    };

    this.boundHandleHashChange = this.handleHashChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.boundHandleHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.boundHandleHashChange);
  }

  getActiveTab(userRole) {
    if (window.location.hash.indexOf("communications") !== -1) {
      return "communications";
    } else if (window.location.hash.indexOf("engagements") !== -1) {
      return "engagements";
    } else if (window.location.hash.indexOf("resolution-cases") !== -1) {
      return "resolution-cases";
    } else if (window.location.hash.indexOf("task") !== -1 && window.location.hash.indexOf("tax-prep-tasks") === -1) {
      return "tasks";
    } else if (window.location.hash.indexOf("transcripts") !== -1) {
      return "transcripts";
    } else if (window.location.hash.indexOf("files") !== -1) {
      return "files";
    } else if (userRole === "Client" && window.location.hash.indexOf("billing") !== -1) {
      return "payments";
    } else if (window.location.hash.indexOf("notes") !== -1) {
      return "notes";
    } else if (window.location.hash.indexOf("dates") !== -1) {
      return "dates";
    } else if (window.location.hash.indexOf("billing") !== -1) {
      return "billing";
    } else if (window.location.hash.indexOf("time") !== -1) {
      return "time-entries";
    } else if (window.location.hash.indexOf("requests") !== -1) {
      return "requests";
    } else if (window.location.hash.indexOf("tax-prep-tasks") !== -1) {
      return "tax-returns";
    } else {
      return "home";
    }
  }

  setActiveTab(activeTab, e) {
    this.setState({
      activeTab: activeTab,
    });

    return canopyUrls.navigateToUrl.call(this, e);
  }

  handleHashChange(event) {
    this.setState({
      activeTab: this.getActiveTab(),
    });
  }

  navigateToBilling = () => {
    const { contact, hasAccess } = this.props;
    if (hasAccess("billing_dashboard")) {
      return `/#/client/${contact.id}/billing/dashboard`;
    } else if (hasAccess("billing_invoices")) {
      return `/#/client/${contact.id}/billing/invoices`;
    } else if (hasAccess("billing_payments")) {
      return `/#/client/${contact.id}/billing/payments/collected`;
    } else if (hasAccess("billing_credits")) {
      return `/#/client/${contact.id}/billing/credits`;
    } else if (hasAccess("billing_statements")) {
      return `/#/client/${contact.id}/billing/statements`;
    }
    return;
  };

  render() {
    const { contact, hasAccess, userRole, loggedInUser } = this.props;
    const transcriptsOnly = userHasOnlyLicense(loggedInUser, "transcripts");
    return (
      <div className="cps-topnav-secondary__tabs">
        {hasAccess("contacts") && (
          <a
            href={"/#/client/" + contact.id}
            onClick={this.setActiveTab.bind(this, "home")}
            className={
              this.state.activeTab === "home"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Home</span>
          </a>
        )}
        {userRole === "TeamMember" && hasAccess("contacts_email") && (
          <a
            href={"/#/communications/clients/" + contact.id}
            onClick={this.setActiveTab.bind(this, "communications")}
            className={
              this.state.activeTab === "communications"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Communication</span>
          </a>
        )}
        {hasAccess("contacts") && userRole === "TeamMember" && (
          <a
            href={"/#/client/" + contact.id + "/notes"}
            onClick={this.setActiveTab.bind(this, "notes")}
            className={
              this.state.activeTab === "notes"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Notes</span>
          </a>
        )}
        {contact.is_active && hasAccess("files_contact") && hasAccess("files") && (
          <a
            href={"/#/docs/clients/" + contact.id + "/files"}
            onClick={this.setActiveTab.bind(this, "files")}
            className={
              this.state.activeTab === "files"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Files</span>
          </a>
        )}
        {userRole === "TeamMember" && contact.is_active && hasAccess("task_dashboard") && (
          <a
            href={"/#/tasks/clients/" + contact.id + ""}
            onClick={this.setActiveTab.bind(this, "tasks")}
            className={
              this.state.activeTab === "tasks"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>{transcriptsOnly ? "Notices" : "Tasks"}</span>
          </a>
        )}
        {featureEnabled("toggle_engagements_rename") && hasAccess("tasks_resolution_cases") && (
          <a
            href={"/#/client/" + contact.id + "/resolution-cases/list/active"}
            onClick={this.setActiveTab.bind(this, "resolution-cases")}
            className={
              this.state.activeTab === "resolution-cases"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Resolution Cases</span>
          </a>
        )}
        {!featureEnabled("toggle_engagements_rename") && hasAccess("tasks_engagements") && (
          <a
            href={"/#/client/" + contact.id + "/engagements/list/active"}
            onClick={this.setActiveTab.bind(this, "engagements")}
            className={
              this.state.activeTab === "engagements"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Engagements</span>
          </a>
        )}
        {contact.is_active && hasAccess("tasks_organizers") && (
          <a
            href={`/#/tax-prep-tasks/clients/${contact.id}`}
            onClick={this.setActiveTab.bind(this, "tax-returns")}
            className={
              this.state.activeTab === "tax-returns"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Organizers</span>
          </a>
        )}
        {hasAccess("transcripts") && userRole === "TeamMember" && (
          <a
            href={`/#/client/${contact.id}/transcripts/reports`}
            onClick={(e) => {
              this.setActiveTab("transcripts", e);
              notifyAnalytics(
                this.props.loggedInUser,
                "transcripts",
                "transcripts",
                "transcripts.client_level_tab_clicked"
              );
            }}
            className={
              this.state.activeTab === "transcripts"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Transcripts</span>
          </a>
        )}
        {contact.is_active && hasAccess("client_billing") && (
          <a
            href={"/#/client/" + contact.id + "/billing"}
            onClick={this.setActiveTab.bind(this, "payments")}
            className={
              this.state.activeTab === "payments"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Payments</span>
          </a>
        )}
        {contact.is_active && this.navigateToBilling() && (
          <a
            href={this.navigateToBilling()}
            onClick={this.setActiveTab.bind(this, "billing")}
            className={
              this.state.activeTab === "billing"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Billing</span>
          </a>
        )}
        {contact.is_active && hasAccess("time_tracking") && (
          <a
            href={`/#/client/${contact.id}/${featureEnabled("timers") ? "time" : "billing/projects/time"}`}
            onClick={this.setActiveTab.bind(this, "billing")}
            className={
              this.state.activeTab === "time-entries"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Time Entries</span>
          </a>
        )}
        {userRole === "Client" && hasAccess("client_tax_resolution") && (
          <a
            href={"/#/client/" + contact.id + "/requests"}
            onClick={this.setActiveTab.bind(this, "requests")}
            className={
              this.state.activeTab === "requests"
                ? "cps-topnav-secondary__tabs__tab +active"
                : "cps-topnav-secondary__tabs__tab"
            }
          >
            <span>Requests</span>
          </a>
        )}
      </div>
    );
  }
}

Tabs.propTypes = {
  contact: PropTypes.object,
  hasAccess: PropTypes.func,
  userRole: PropTypes.string,
};
