import React from "react";
import FetchContact from "./fetch-contact.component.js";
import { HashRouter, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient, queryContext } from "global-settings!sofe";
import { ErrorBoundary } from "error-logging!sofe";
import "./contact-menu.js";

@ErrorBoundary({ featureName: "contact record" })
@hot(module)
export default class Root extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient} context={queryContext}>
        <HashRouter basename="/">
          <div>
            <Switch>
              <Route path="/task/:taskId/client/:clientId" component={FetchContact} />
              <Route path="(.*)/client/:clientId" component={FetchContact} exact={false} />
              <Route path="(.*)/clients/:clientId" component={FetchContact} exact={false} />
            </Switch>
          </div>
        </HashRouter>
      </QueryClientProvider>
    );
  }
}
