import React from "react";
import { get } from "lodash";
import Cancelable from "react-disposable-decorator";
import { getContact } from "./contacts.resource.js";
import { redirectOrCatch } from "fetcher!sofe";
import ContactMenu from "./contact-menu.component.js";
import { setRecentContacts } from "./recent-contacts.helper.js";
import { UserTenantProps } from "cp-client-auth!sofe";

@Cancelable
@UserTenantProps()
export default class FetchContact extends React.Component {
  state = {
    contact: null,
  };

  componentDidMount() {
    this.fetchContact(this.props.match.params.clientId);
    this.setupListener(this.props.match.params.clientId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.clientId !== this.props.match.params.clientId) {
      this.props.cancelAllSubscriptions();
      this.fetchContact(this.props.match.params.clientId);
      this.destroyListener(prevProps.match.params.clientId);
      this.setupListener(this.props.match.params.clientId);
    }
  }

  componentWillUnmount() {
    this.destroyListener(this.props.match.params.clientId);
    this.bubbleSub?.unsubscribe();
  }

  render() {
    if (!this.state.contact) {
      return null;
    } else {
      return (
        <ContactMenu
          loggedInUser={this.props.loggedInUser}
          tenant={this.props.tenant}
          contact={this.state.contact}
          refetchContact={this.refetchContact}
        />
      );
    }
  }

  fetchContact = (clientId, forceBust) => {
    this.props.cancelWhenUnmounted(
      getContact(clientId, forceBust).subscribe((contact) => {
        this.setState({ contact });
        setRecentContacts(contact, this.props.loggedInUser.id);
      }, redirectOrCatch())
    );
  };

  refetchContact = () => {
    const clientId = get(this.props, "match.params.clientId");
    this.fetchContact(clientId, true);
  };

  setupListener = (clientId) => {
    if (clientId) {
      window.addEventListener(`cp:contacts:${clientId}-edited`, this.refetchContact);
    }
  };

  destroyListener = (clientId) => {
    if (clientId) {
      window.removeEventListener(`cp:contacts:${clientId}-edited`, this.refetchContact);
    }
  };
}
