import canopyUrls from "canopy-urls!sofe";
import fetcher, { fetchWithSharedCache, fetchAsObservable } from "fetcher!sofe";
import { map, pluck } from "rxjs/operators";
import { partial } from "lodash";

export function getContact(contactId, forceBust = false) {
  if (!contactId) {
    throw new Error(`Cannot get contact without id`);
  }

  return fetchWithSharedCache(
    `api/clients/${contactId}?include=users,contacts,tags,contact_for,contact_sources,integrations,roles,general_assigned`,
    partial(untilClientIdHasChanged, contactId),
    forceBust
  ).pipe(pluck("clients"));
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}

export function updateContactName(contactId, formData) {
  let url = `${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`;
  let body = {
    clients: formData,
  };
  return promise(url, "PATCH", body);
}

export function updateContactType(contactId, type, oldType) {
  let url = `${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`;
  let body = {
    clients: {
      contact_type: type,
    },
  };
  if (type == "client" && oldType != "client") {
    body.clients.client_since = new Date().getTime();
  }
  return promise(url, "PATCH", body);
}

export function archiveContact(contactId) {
  let url = `${canopyUrls.getWorkflowUrl()}/api/contacts/${contactId}/archive`;
  return promise(url, "POST");
}

export function unarchiveContact(contactId) {
  return fetchAsObservable(`api/contacts/${contactId}/unarchive`, { method: "POST" });
}

export function deleteContact(contactId) {
  let url = `${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`;
  return promise(url, "DELETE");
}

function promise(url, method, body) {
  return new Promise((resolve, reject) => {
    fetcher(url, {
      method: method,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then((json) => {
              resolve(json.clients);
            })
            .catch((ex) => {
              reject(ex);
            });
        } else {
          reject();
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function getTenantTeamMembers() {
  return fetchAsObservable(`${canopyUrls.getAPIUrl()}/users?role=TeamMember`).pipe(
    map((response) => {
      return response.users;
    })
  );
}

export function modifyContactUsers(contactId, userIds, newUserIds) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/contacts/${contactId}/team-members`, {
    method: "PUT",
    body: {
      team_members: userIds,
      notifications: {
        users: newUserIds,
        messages: {
          team_message:
            "Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress for this contact.",
        },
      },
    },
  });
}

export function getContactsUsage() {
  return fetchAsObservable(`api/contacts/reached_limit`);
}

export function putRoleAssignments(clientId, body) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}
