import React, { Suspense, useMemo, useState, lazy } from "react";
import PropTypes from "prop-types";
import { uniq } from "lodash";
import { CpIcon, CpNameChip, CpTooltip, CpLoader } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { useTeamMembersQuery, getAssignUsersModal } from "contacts-ui!sofe";
import styles from "./participants.styles.css";

const AssignUsersModal = lazy(getAssignUsersModal);

function Participants(props) {
  const { client, loggedInUser } = props;
  const { users } = client;
  const [showModal, setShowModal] = useState(false);
  const { teamMembers, teams } = useTeamMembersQuery();

  const displayList = useMemo(() => {
    // Ordering them left to right first, and then reversing so that uniq will keep the order correct in cases where there is a duplicate user in general and role assignments
    const userIds = uniq([
      ...client.general_assigned.users,
      ...client.role_assignments.flatMap((r) => r.users || []),
    ]).reverse();
    const teamIds = uniq([
      ...client.general_assigned.teams,
      ...client.role_assignments.flatMap((r) => r.teams || []),
    ]).reverse();

    const flatList = [
      ...teamIds.map((teamId) => teams.find((t) => t.id === teamId)),
      ...userIds.map((userId) => teamMembers.find((tm) => tm.id === userId)),
    ].filter((item) => !!item);

    const displayLimit = 6;
    const hiddenList = flatList.slice(displayLimit, flatList.length);

    return [
      ...(hiddenList.length > 0
        ? [{ id: -1, truncatedUsersCount: hiddenList.length, tooltip: hiddenList.map((item) => item.name).join(", ") }]
        : []),
      ...flatList.slice(0, displayLimit),
    ];
  }, [client, teamMembers, teams]);

  const canAdd = useHasAccess("contacts_assign_team_members");

  return (
    <>
      <ul className="cps-topnav-secondary__content__right-menu +tall" style={{ whiteSpace: "nowrap", display: "flex" }}>
        {users &&
          displayList.map((user) => {
            return (
              <CpTooltip text={user.name || user.tooltip} key={user.id}>
                <li className="cp-pr-4">
                  <CpNameChip
                    count={user.truncatedUsersCount}
                    name={user.name}
                    active={loggedInUser.id === user.id}
                    imgSrc={user.profile_picture_url}
                    team={user.users ? user : null}
                  />
                </li>
              </CpTooltip>
            );
          })}

        {canAdd && (
          <li
            onClick={() => {
              setShowModal(true);
            }}
          >
            <div
              style={{ position: "relative" }}
              className={`${styles.dropDownSquare} cps-label-square +medium +active`}
            >
              <div className="cps-center-vertical">
                <CpIcon name="person-add" />
              </div>
            </div>
          </li>
        )}
      </ul>
      {canAdd && showModal && (
        <Suspense fallback={<CpLoader />}>
          <AssignUsersModal
            clientId={client.id}
            onClose={() => setShowModal(false)}
            onAssignmentsUpdated={() => {
              window.dispatchEvent(new CustomEvent(`cp:contacts:${client.id}-edited`)); // trigger refetch of the client
            }}
          />
        </Suspense>
      )}
    </>
  );
}

export default Participants;

Participants.propTypes = {
  client: PropTypes.object,
};
